<template>
  <a-drawer width="40%" :label-col="4" :wrapper-col="14" :visible="open" @close="onClose" destroy-on-close>
    <a-divider orientation="left">
      <b>{{ formTitle }}</b>
    </a-divider>
    <a-form-model ref="form" :model="form" :rules="rules">
      <a-form-model-item label="资料类型" prop="profileType" >
        <a-radio-group v-model="form.profileType">
          <a-radio :value="1">复印资料</a-radio>
          <a-radio :value="2">原件资料</a-radio>
        </a-radio-group>
      </a-form-model-item>
      <a-form-model-item label="类型" prop="type" >
        <a-select v-model="form.type" placeholder="请选择">
          <a-select-option :value="1">图文</a-select-option>
          <a-select-option :value="2">cover letter</a-select-option>
          <a-select-option :value="3">工作证明</a-select-option>
          <a-select-option :value="4">行程单</a-select-option>
<!--          <a-select-option :value="5">在读证明</a-select-option>-->
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="标题" prop="title" >
        <a-input :maxLength="100" v-model="form.title" placeholder="请输入标题" />
      </a-form-model-item>

      <a-form-model-item label="轮播图" prop="image" v-if="form.type == 1">
        <file-upload
          type="image"
          :defaultList="form.imageArr"
          :count="60"
          @input="getImg($event, 'image')"
        ></file-upload>
        <span style="color: red">建议上传图片尺寸：686 x 320 px</span>
      </a-form-model-item>

      <a-form-model-item label="内容" prop="content" v-if="form.type == 1">
        <editor v-model="form.content" />
      </a-form-model-item>

      <a-form-model-item label="提示语" prop="prompt">
        <a-input :maxLength="200" v-model="form.prompt" placeholder="请输入提示语" type="textarea"/>
      </a-form-model-item>

      <a-form-model-item label="排序" prop="sort">
        <a-input-number style="width: 300px" v-model="form.sort" />
      </a-form-model-item>
      <div class="bottom-control">
        <a-space>
          <a-button type="primary" :loading="submitLoading" @click="submitForm">
            保存
          </a-button>
          <a-button type="dashed" @click="cancel">
            取消
          </a-button>
        </a-space>
      </div>
    </a-form-model>
  </a-drawer>
</template>

<script>
import { getProfile, addProfile, updateProfile } from '@/api/visa/profile'
import Editor from '@/components/Editor'
import {guid} from "@/utils/ruoyi";

export default {
  name: 'CreateForm',
  props: {
  },
  components: {
    Editor
  },
  data () {
    return {
      submitLoading: false,
      formTitle: '',
      // 表单参数
      form: {
        id: null,

        profileType: null,

        type: null,
        image:null,
        imageArr:[],
        imageCount:60,
        title: null,
        prompt:null,
        content: null,
        sort:null,
        createTime: null,

        remark: null,

      },
      // 1增加,2修改
      formType: 1,
      open: false,
      rules: {
        profileType: [
          { required: true, message: '资料类型不能为空', trigger: 'change' }
        ],
        type: [
          { required: true, message: '类型不能为空', trigger: 'change' }
        ],
        title: [
          { required: true, message: '标题不能为空', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '内容不能为空', trigger: 'blur' }
        ]
      }
    }
  },
  filters: {
  },
  created () {
  },
  computed: {
  },
  watch: {
  },
  mounted () {
  },
  methods: {
    onClose () {
      this.open = false
    },
    // 取消按钮
    cancel () {
      this.open = false
      this.reset()
    },
    /**上传图片成功回调 */
    getImg(info, fieldName) {
      console.log('info', info)
      if (info.length === 0) {
        this.form[fieldName] = ''
        this.form[`${fieldName}Arr`] = []
      } else {
        if (this.form[`${fieldName}Count`] === 1) {
          this.form[fieldName] = info[0].url
          this.form[`${fieldName}Arr`] = info
        } else {
          this.form[fieldName] = info
            .map((ele) => {
              return ele.url
            })
            .join(',')
          this.form[`${fieldName}Arr`] = info
        }
      }
    },

    // 表单重置
    reset () {
      this.formType = 1
      this.form = {
        id: null,
        profileType: null,
        type: null,
        title: null,
        image:null,
        imageArr:[],
        imageCount:60,
        prompt: null,
        sort:null,
        content: null,
        createTime: null,
        remark: null,
      }
      this.$nextTick(() => {
        if(this.$refs.form){
          this.$refs.form.resetFields()
        }
      })
    },
    /** 新增按钮操作 */
    handleAdd (row) {
      this.reset()
      this.formType = 1
      this.open = true
      this.formTitle = '添加'
    },
    /** 修改按钮操作 */
    handleUpdate (row, ids) {
      this.open = true
      this.reset()
      this.formType = 2
      const id = row ? row.id : ids
      getProfile({"id":id}).then(response => {
        this.form = response.data
        this.formTitle = '修改'
        this.form.imageArr = []
        let that  =this
        if(this.form.image){
          this.form.image.split(',').forEach(item =>{
            that.form.imageArr.push({
              uid: guid(8, 10),
              name: item,
              status: 'done',
              halfUrl: item,
              url: item,
              path: item,
            })
          })
        }
      })
    },
    /** 提交按钮 */
    submitForm: function () {
      this.$refs.form.validate(valid => {
        if (valid) {
          this.submitLoading = true
          if (this.form.id !== undefined && this.form.id !== null) {
            updateProfile(this.form).then(response => {
              this.$message.success(
                '修改成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          } else {
            addProfile(this.form).then(response => {
              this.$message.success(
                '新增成功',
                3
              )
              this.open = false
              this.$emit('ok')
            }).finally(() => {
              this.submitLoading = false
            })
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
